/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr } from "./utils"
 import { useLocation } from "@reach/router"

 const SeoDubaiResults = ({ description, lang, meta, title, searchParams, location, url: metaurl }) => {
 
   const {areas, bedrooms, pType, type, price } = searchParams;

   var { pathname } = useLocation()
 
   const url = metaurl
  ? metaurl
  :null;
   const actionStr = ACTION_TYPES[pType];
   const priceStr = getPriceStr(price)
  //  const bedRooms = searchParams.bedrooms
 
   let propertyType = ""
 
   if(type === "") {
     propertyType = "property"
   } else {
     propertyType = type
   }
 
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let desc = "";
   desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Bentley Hurst Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
 
   if ('manchester' !== areas) {
     desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Bentley Hurst Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
   }
 
   if ('sales' === pType) {
     desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Bentley Hurst Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
 
     if ('manchester' !== areas) {
         desc = "Discover a wide range of "+ propertyType + actionStr + areas +" with Bentley Hurst Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + areas + ". please contact us.";
     }
   } else {
     // price
     if (price.min || price.max){
       desc = propertyType + actionStr + areas + "."
     }
 
   }
 
     //
     let titlePropertyType = ""
 
     if(type === "") {
         titlePropertyType = "Property"
     } else if(type === "apartment") {
         titlePropertyType = "Apartments"
     } else if(type === "house") {
         titlePropertyType = "Houses"
     } else if(type === "maisonette") {
         titlePropertyType = "Maisonette"
     } else if(type === "property") {
         titlePropertyType = "Property"
     } else if(type === "bungalow") {
         titlePropertyType = "Bungalow"
     } else if(type === "land") {
         titlePropertyType = "Land"
     } else if(type === "commercial") {
         titlePropertyType = "Commercial"
     } else {
         titlePropertyType = type
     }
 
     let bedroomType = ""
 
     if ( bedrooms ) {
       bedroomType = bedrooms + ' + Bedroom '
     }
 
     let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr
     //
 
   // console.log("Props Desc => ", desc);
 
   description = desc

  var curentUrl = '';

  if (pathname == "/property/to-rent/" || pathname == "/property/to-rent" )
    pathname = "/property/to-rent/in-manchester/"
  if (pathname == "/property/for-sale/" || pathname == "/property/for-sale" )
    pathname = "/property/for-sale/in-manchester/"

  curentUrl = process.env.GATSBY_SITE_URL+pathname;

  // set no index for result pagination
  let hasPagination = false;
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';
 
   let metaItems = [
     {
       name: `description`,
       content: `For finding your dream new home effortlessly, you can filter and sort results. If you have any questions about your purchase, reach out to your local agents at Bentley Hurst.`,
     },
     {
       property: `og:title`,
       content: descTitle,
     },
     {
       property: `og:description`,
       content: description,
     },
     {
       property: `og:type`,
       content: `website`,
     },
      {
        name: `og:url`,
        content: curentUrl,
      },
     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Bentley Hurst`,
     },
     {
       name: `twitter:title`,
       content: descTitle,
     },
     {
       name: `twitter:description`,
       content: description,
     },
     {
       property: "og:url",
       content:url,
     },
     {
       property: `og:image`,
       content: `${process.env.GATSBY_SITE_URL}/images/mail-logo.png`,
     },
   ].concat(meta);
 
 
   if ( hasPagination ) {
     // metaItems.push({
     //   name: `robots`,
     //   content: `noindex`,
     // })
   }
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={`Dubai Developments`}
      link={[
          {
              href: curentUrl,
              rel: "canonical"
          }
      ]}
       titleTemplate={`%s | Bentley Hurst`}
       meta={metaItems}
     />
   )
 }
 
 SeoDubaiResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 SeoDubaiResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default SeoDubaiResults
 
